<template>
<el-container class="home-current">
  <h1>Register</h1>
  <el-header>
    <el-steps :active="2" align-center>
      <el-step title="Verify your phone" description=""></el-step>
      <el-step title="Register" description=""></el-step>
    </el-steps>
  </el-header>
  <el-main>
    <el-form id="register-form" :model="userData" :rules="rulesUser" ref="userData" label-width="120px" class="demo-ruleForm"  :label-position="labelPosition">
      <el-row class="name">
        <el-col :xs="24" :sm="6" :md="6" :lg="5"  :xl="5">
          <el-form-item prop="firstName" required>
            <el-input placeholder="First name"  v-model="userData.firstName"></el-input>
          </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="6" :md="6" :lg="5" :xl="5">
          <el-form-item prop="lastName" required>
            <el-input placeholder="Last name" v-model="userData.lastName"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="email">
        <el-col :xs="24" :sm="8" :md="8"  :lg="7" :xl="7">
          <el-form-item prop="email" required>
            <el-input placeholder="Email" v-model="userData.email"></el-input>
          </el-form-item>
         </el-col>
        <el-col :xs="24"  :sm="4"  :md="4"  :lg="3" :xl="3">
          <el-form-item prop="currency" required>
            <el-select v-model="userData.currency" id="pkjs" :disabled="disabled" @visible-change="setReadOnly" autocomplete="off" >
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="password">
        <el-col :xs="24" :sm="12" :md="12" :lg="10" :xl="10">
          <el-form-item prop="password" autoComplete="off" required>
            <el-input placeholder="Password" autoComplete="off" v-model="userData.password" show-password></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row class="password-validators">
        <el-col :xs="24" :sm="7" :md="8" :lg="7" :xl="4" class="pass-rules">
          <el-checkbox v-model="strongPasswordStatus.upperCase" disabled>At least one capital letter</el-checkbox>
          <el-checkbox v-model="strongPasswordStatus.lowerCase" disabled>At least one lower case letter</el-checkbox>
          <el-checkbox v-model="strongPasswordStatus.digit" disabled>At least one number</el-checkbox>
          <el-checkbox v-model="strongPasswordStatus.min" disabled>Minimum 8 characters</el-checkbox>
          <el-checkbox v-model="strongPasswordStatus.specialCharacter" disabled>At least one special symbol !@#$%()_</el-checkbox>
        </el-col>
      </el-row>
      <el-row class="register">
         <el-col :xs="9" :sm="6" :md="4" :lg="12" :xl="6">
           <el-form-item>
              <el-button type="primary" round v-on:click="registerUsers('userData')" >Submit</el-button>
           </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <form @submit.prevent="handleTermError">
      <div class="bg-modal" id="modalPopup" style="display:none !important">
        <div class="modal-content" >
          <div class="content-inner-tnc">
            <!--<el-button type="primary" round v-on:click="AcceptTnc(acpt='Deny')" >Close</el-button>-->
              <p style="text-align:left; font-size: 14px;"><strong>To create your account, confirm the following:</strong></p> 
                  <p class="list-items"><span>1.</span> I have read and understood the FinPros’ <strong>‘<a target="_blank" href="http://finpros.com/wp-content/uploads/2022/05/FinPros.com-Client-Agreement-Terms-and-Conditions-of-Business.pdf">Client Agreement – Terms & Conditions of Business</a>’, ‘<a target="_blank" href="http://finpros.com/wp-content/uploads/2022/05/FinPros.com-Best-Interest-Order-Execution-Policy.pdf">Best Interest & Order Execution Policy</a>’, ‘<a target="_blank" href="http://finpros.com/wp-content/uploads/2022/05/FinPros.com-Leverage-and-Margin-Policy.pdf">Leverage and Margin Policy</a>’, ‘<a target="_blank" href="http://finpros.com/wp-content/uploads/2022/05/FinPros.com-Complaints-Handling-Policy-Procedures.pdf">Complaints Handling Policy & Procedures</a>’, ‘<a target="_blank" href="http://finpros.com/wp-content/uploads/2022/05/FinPros.com-Website-Terms-Conditions-of-Access-Use.pdf">Website Terms & Conditions of Access & Use</a>’, ‘<a target="_blank" href="http://finpros.com/wp-content/uploads/2022/05/FinPros.com-Deposits-and-Withdrawals-Policy.pdf">Deposits and Withdrawals Policy</a>’, ‘<a target="_blank" href="http://finpros.com/wp-content/uploads/2022/05/FinPros.com-Money-Laundering-and-Terrorist-Financing-Prevention-Policy.pdf">AML Policy</a>’</strong> and <strong>‘<a target="_blank" href="http://finpros.com/wp-content/uploads/2022/05/FinPros.com-Risk-Disclosure-Notice.pdf">Risk Disclosure Notice</a>’</strong> and I agree to all of the provisions contained therein.</p>
                  <p class="list-items"><span>2.</span> I consent to the use of my information in accordance with FinPros’ <strong>‘<a target="_blank" href="http://finpros.com/wp-content/uploads/2022/05/FinPros.com-Privacy-Policy.pdf">Privacy Policy</a>’</strong> and <strong>‘<a target="_blank" href="http://finpros.com/wp-content/uploads/2022/05/FinPros.com-Cookie-Policy.pdf">Cookies Policy</a>’</strong>.</p>
                
               
              <el-button type="primary" round v-on:click="AcceptTnc(acpt='Accept')" >I Agree</el-button>
              <p class="list-items last" style="text-align:justify !important">By clicking the <strong>“I Agree”</strong> button above you electronically sign the online application and authorize us to send you periodic updates, company news and technical analysis. You additionally agree that no certification authority or other third-party verification is necessary to validate your electronic signature. This will not in any way affect the enforceability of your signature and the resulting contract.</p>
              <p class="list-items last"><strong>This is a legally binding contract. By registering you declare that your read and accepted the foregoing completely.</strong></p>

          </div>
        </div>
      </div>
    </form>
  </el-main>
</el-container>
</template>

<script>
// import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/display.css';
import axios from "axios";
import $ from 'jquery'

export default {
  name: 'RegisterComponent',
  props: {
    phone: String,
    code: String,
    
  },
  data: function (){
    let passwordValidationUpperCase = (rule, value, callback) => {
      if (!/[A-Z]/.test(value)){
        callback(new Error('Use at least one capital letter'))
        this.strongPasswordStatus.upperCase = false;
        this.strongPasswordStatus.lowerCase = false;
      }else {
        this.strongPasswordStatus.upperCase = true;
        callback();
      }
    };

    let passwordValidationLowerCase = (rule, value, callback) => {
      if (!/[a-z]/.test(value)){
        callback(new Error('Use at least one lower case letter'))
        this.strongPasswordStatus.lowerCase = false;
      } else {
        this.strongPasswordStatus.lowerCase = true;
        callback();
      }
      
    };
    let passwordValidationMin = (rule, value, callback) => {
      if (value.length < 8){
        callback(new Error('Use at least 8 characters'))
        this.strongPasswordStatus.min = false;
      } else {
        this.strongPasswordStatus.min = true;
        callback();
      }
    };
    let passwordValidationDigit = (rule, value, callback) => {
      if (!/[0-9]/.test(value)){
        callback(new Error('Use at least one number'))
        this.strongPasswordStatus.digit = false;
        this.strongPasswordStatus.specialCharacter = false;
      } else {
        this.strongPasswordStatus.digit = true;
        callback();
      }
    };
    let passwordValidationSymbol = (rule, value, callback) => {
      if (!/[@#$!%_()]/.test(value)){
        callback(new Error('Use at least one special symbol'))
        this.strongPasswordStatus.specialCharacter = false;
      } else {
        this.strongPasswordStatus.specialCharacter = true;
        callback();
      }
    };

    return {
      userData: {
        
        acpt: '',
        url: '',
        firstName: null,
        lastName: null,
        email: null,
        password: null,
        currency: null,
        phone: null,
        code: null,
        validated: false,
        list:[],
        partner: null,
        suid : null,
        utm_campaign : null,
        utm_source : null,
        utm_medium : null,
        utm_term : null,
        utm_content : null,
        utm_keyword : null,
        sidi : null,
        sidc : null,
        campaign_id : null,
        channel_id : null,
        utm_device : null,
        utm_creative : null,
        utm_network : null,
      }, 
      rulesUser: {
        firstName: [
          { required: true, message: 'Please input your first name', trigger: 'blur' },
        ],
        lastName: [
          { required: true, message: 'Please input your last name', trigger: 'blur' },
        ],
        email: [
          { required: true, message: 'Please input your email', trigger: 'blur' },
          { type: 'email', message: 'Please input correct email address', trigger: ['blur', 'change'] }
        ],
        currency: [
          { required: true, message: 'Please select currency', trigger: 'blur' },
        ],
        password: [
          { validator: passwordValidationUpperCase, trigger: ['blur', 'change']},
          { validator: passwordValidationLowerCase, trigger: ['blur', 'change']},
          { validator: passwordValidationDigit, trigger: ['blur', 'change']},
          { validator: passwordValidationMin, trigger: ['blur', 'change']},
          { validator: passwordValidationSymbol, trigger: ['blur', 'change']},
        ],
      },
      options: [
        {
          value: 'EUR',
          label: 'EUR',
        },
        {
          value: 'USD',
          label: 'USD',
        },
        {
          value: 'GBP',
          label: 'GBP',
        },
        {
          value: 'USDT',
          label: 'USDT',
        },
      ],
      labelPosition: 'top',
      strongPasswordStatus: {
        digit: false,
        lowerCase: false,
        upperCase: false,
        min: false,
        specialCharacter: false,
      }
    }
  },
  methods: {
    AcceptTnc: function(acpt){

 
    //this.userData.validated = true
    //console.log(this.userData.validated)
    
    
      const txt = acpt
      this.txt = txt;
      if(this.txt == 'Accept'){
        //window.location.replace(this.userData.url)
        this.registerUser('userData')
      }else if(this.txt == 'Deny'){
        $('.stopScroll').attr("style", "overflow: auto !important");
        $('body').removeClass('stopScroll');
        $('#modalPopup').attr("style", "display: none !important");
      }
      
      
     
    },
    handleTermError(){
      console.log('tnc form submitted')
    },
    registerUsers(formName){
            
      this.$refs[formName].validate((valid)=>{
      if(valid){


            




        $('body').addClass('stopScroll');
        $('.stopScroll').attr("style", "overflow: hidden !important");
        document.getElementById('modalPopup').style.display= "block";
      }
      });
      
    },
    registerUser(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$store.commit('setUserFirstName', this.userData.name)
          this.$store.commit('setUserLastName', this.userData.lastName)
          this.$store.commit('setUserEmail', this.userData.email)
          this.$store.commit('setUserCurrency', this.userData.currency)
          this.$store.commit('setUserPassword', this.userData.password)
          //TODO SEND POST TO CRM REGISTER

          axios.post('https://api.finpros.com/api/register', {
            phone_number: this.userData.phone,
            country_code: this.userData.code,
            email: this.userData.email,
            password: this.userData.password,
            first_name: this.userData.firstName,
            last_name: this.userData.lastName,
            currency: this.userData.currency,
            agreementList: this.userData.list,
            partner: this.userData.partner,

            suid: this.userData.suid,
            utm_campaign: this.userData.utmCampaign,
            utm_source: this.userData.utmSource,
            utm_medium: this.userData.utmMedium,
            utm_term:   this.userData.utmTerm,
            utm_content:this.userData.utmContent,
            utm_keyword: this.userData.utmKeyword,

            sidi: this.userData.sidi,
            sidc: this.userData.sidc,
            campaign_id: this.userData.campaignId,
            channel_id: this.userData.channelId,
            utm_device:   this.userData.utmDevice,
            utm_creative:this.userData.utmCreative,
            utm_network: this.userData.utmNetwork,
          })
              .then((response) => {
                if (response.data.token) {
                  //this.successMessage('Test', response.data.url)
                  //this.userData.url = response.data.url

                  //document.getElementById('modalPopup').style.display= "none";
                  window.location.replace(response.data.url)
                } else {
                  this.errorMessage('Error', response.data.msg)
                }
              })
              .catch((error) => {
               console.log(error)
                this.errorMessage('Error', 'Request can not be proceed due to some technical reason. Please try after some time.')
              });

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    successMessage(mTitle, mMessage) {
      this.$notify.success({
        title: mTitle,
        message: mMessage,
      });
    },
    errorMessage(mTitle, mMessage) {
      this.$notify.error({
        title: mTitle,
        message: mMessage,
      });
    },
    setReadOnly(isVisiable){
      if(isVisiable){
        document.getElementById("pkjs").setAttribute("readonly", "readonly");
      }
    } ,   
  },
  mounted: function() {
    this.userData.phone = this.$store.getters.getUserPhone;
    this.userData.code = this.$store.getters.getUserPhoneCode;

    this.userData.partner = this.$store.getters.getPartner;

    this.userData.suid = this.$store.getters.getSuid;
    this.userData.utmCampaign = this.$store.getters.getUtmCampaign;
    this.userData.utmSource = this.$store.getters.getUtmSource;
    this.userData.utmMedium = this.$store.getters.getUtmMedium;
    this.userData.utmTerm = this.$store.getters.getUtmTerm;
    this.userData.utmContent = this.$store.getters.getUtmContent;
    this.userData.utmKeyword = this.$store.getters.getUtmKeyword;

    this.userData.sidi = this.$store.getters.getSidi;
    this.userData.sidc = this.$store.getters.getSidc;
    this.userData.campaignId = this.$store.getters.getCampaignId;
    this.userData.channelId = this.$store.getters.getChannelId;
    this.userData.utmDevice = this.$store.getters.getUtmDevice;
    this.userData.utmCreative = this.$store.getters.getUtmCreative;
    this.userData.utmNetwork = this.$store.getters.getUtmNetwork;


  axios.post("https://api.finpros.com/api/getAgreementsList", {})
    .then((response) => {
    //console.log(response.data.agree_data)
      this.userData.list = response.data.agree_data
  });

  let elements = document.querySelectorAll('[autocomplete="off"]');
  var i = 1;
  elements.forEach(function(element) {
    if(i == 4){
      element.setAttribute("readonly", "readonly");
    }
    i++;
  })

  },
  computed: {
  
    
  },
}

window.addEventListener('keydown', function (event) {
  if (event.key === 'Escape') {
    document.getElementById('modalPopup').style.display= "none";
  }
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="css">
body, .home-current {
font-family: 'Poppins',Sans-serif !important;
}
.name,.email,.password,.register{
  margin-top: 25px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}
.tnc-error-agree {
    color: #F56C6C;
    font-size: 12px;
    line-height: 1;
    padding-top: 4px;
    top: 100%;
    left: 0;
}

/*div{*/
/*div{*/
/*  margin-bottom: 1rem;*/
/*}*/
/*}*/
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.password-validators{
  display: flex;
  justify-content: center;
}
.password-validators div{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.el-checkbox__inner::after {
  border-color: green!important;
}

@media only screen and (max-width: 600px){
  h1{
    font-size: 2rem;
  }
}
@media only screen and (max-width: 768px){
  .el-select {
    width: 100%;
  }

  .name{
    margin-top: 0;
  }

  .email,.register,.password{
    margin-top: 0;
  }
  .pass-rules{
    display: flex;
    flex-direction: column;
    /* margin-left: auto; */
    flex-wrap: wrap;
    text-align: left;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
}

.el-checkbox {
  font-weight: 400;
}
.el-form-item__error{
  font-family: 'Poppins', Sans-serif;
}
button.el-button.el-button--primary.is-round {
    font-family: 'Poppins', Sans-serif;
}
.el-main {
  font-family: 'Poppins', Sans-serif !important;
}
body.stopScroll{
  overflow: hidden !important;
}

div#modalPopup:before {
    content: '';
    background-color: rgba(0,0,0,0.7);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
}

.content-inner-tnc{
  height: 510px;
  padding-right: 20px;
  overflow-y: auto;
  font-family: 'Poppins', Sans-serif;
}

.modal-content{
    width: 100%;
    max-width: 400px;
    height: 500px;
    background: white;
    opacity: 1;
    border-radius: 12px;
    text-align: center;
    padding: 25px 10px 25px 25px;
    z-index: 99;
    position: fixed;
    top: 20px;
    left: 0;
    right: 0;
    margin: 0 auto;
    font-family: 'Poppins',Sans-serif !important;
}
.list-items.last{
padding-left: 0px !important; 
}
.list-items{
  position:relative;
  padding-left: 30px;
  text-align: justify;
  font-size: 12px;
}
.list-items span{
  position: absolute;
  left: 0;
  top: 0;
}
.list-items a{
  color: #15A2F1 !important;
}
</style>
