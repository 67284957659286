<template>
  <div class="about">
    <Register/>
  </div>
</template>

<script>
// @ is an alias to /src
import Register from '@/components/Register.vue'

export default {
  name: 'RegisterUser',
  components: {
    Register
  }
}
</script>